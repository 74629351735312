





















































































import AddResourceForm from './components/AddResourceForm.vue';
import EditResourceForm from './components/EditResourceForm.vue';
import EditTopicForm from './components/EditTopicForm.vue';
import MultipleClientInput from '@/components/inputs/MultipleClientInput.vue';
import ProfileTile from '../../shared/components/ProfileTile.vue';
import QRCode from 'qrcode';
import { Client, Resource, ResourceTopic } from '@/models';
import { defineComponent, onMounted, ref } from '@/plugins/composition';
import { routeNames } from '@/router/routeNames';
import { useRouter } from '@/router';

export default defineComponent({
  components: { ProfileTile, EditTopicForm, EditResourceForm, AddResourceForm, MultipleClientInput },
  name: 'ViewTopicPage',
  props: {
    uuid: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const topic = ref<ResourceTopic | null>(null);
    const resources = ref<Resource[]>([]);
    const adding = ref(false);
    const clients = ref<Client[]>([]);
    const qrcodeDialog = ref(false);
    const client = ref<null | Client>(null);
    const { router } = useRouter();

    const loadResources = async () => {
      resources.value = (await topic.value?.resources().getAll()) ?? [];
    };

    const loadTopic = async () => {
      topic.value = await ResourceTopic.find(props.uuid);
      clients.value = topic.value.clients;
    };

    onMounted(async () => {
      await loadTopic();
      await loadResources();
    });

    const added = async () => {
      adding.value = false;
      await loadResources();
    };

    const updateClients = async () => {
      topic.value?.updateRaw({
        clients: clients.value.map((client: Client) => client.uuid),
      });
    };

    const downloadQrCode = async () => {
      try {
        const path = router.resolve({
          name: routeNames.patient.goToResourceTopic,
          query: { topic: props.uuid, client: client.value?.uuid },
        });

        const url = window.location.protocol + '//' + window.location.host + path.href;

        try {
          navigator.clipboard.writeText(url);
        } catch (e) {
          console.log(url);
          // fail silently
        }

        const data = await QRCode.toDataURL(url, { color: { light: '#ffffff', dark: '#2abf64' }, width: 500 });

        const link = document.createElement('a');
        link.href = data;
        link.download = client.value?.name + ' - ' + topic.value?.data.name;
        link.click();
        URL.revokeObjectURL(link.href);
      } catch (err) {
        console.error(err);
      }
    };

    return {
      topic,
      resources,
      adding,
      added,
      clients,
      updateClients,
      qrcodeDialog,
      client,
      downloadQrCode,
    };
  },
});
