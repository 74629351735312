
















import { Client } from '@/models';
import { PropType, computed, defineComponent, onMounted, ref } from '@/plugins/composition';

export default defineComponent({
  name: 'MultipleClientInput',
  props: {
    value: {
      type: Array as PropType<Client[]>,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const clients = ref<Client[]>([]);

    const selected = computed({
      get: () => props.value,
      set: (items: Client[]) => {
        emit('input', items);
      },
    });

    onMounted(async () => {
      clients.value = await Client.getAll();
    });

    return {
      clients,
      selected,
    };
  },
});
