






































import { PropType, computed, defineComponent, reactive, ref } from '@/plugins/composition';
import { ResourceTopic } from '@/models';
import { axios } from '@/plugins/axios';
import { snakeKeys } from '@/utils';

export default defineComponent({
  name: 'AddResourceForm',
  props: {
    topic: {
      type: Object as PropType<ResourceTopic>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const editing = ref(false);
    const type = ref('Video');
    const resource = reactive({
      uuid: '',
      name: '',
      type: 'video',
      videoId: '',
      url: '',
    });
    const file = ref<null | File>(null);
    const resourceTypes = ['Video', 'File'];

    const create = async () => {
      console.log(resource);
      if (type.value === 'File') {
        await createWithFile();
      } else {
        await axios.post(props.topic.resourceSlug() + 'resources', snakeKeys(resource));
      }
      emit('input');
    };

    const createWithFile = async () => {
      const formData = new FormData();
      formData.append('file', file.value as File);
      formData.append('name', resource.name);
      formData.append('type', type.value.toLowerCase());

      await axios.post(props.topic.resourceSlug() + 'resources', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
    };

    const valid = computed(() => {
      if (!resource.name) {
        return false;
      }
      if (type.value === 'Video') {
        if (!resource.videoId) {
          return false;
        }
      }
      return true;
    });

    return {
      editing,
      create,
      resource,
      emit,
      resourceTypes,
      valid,
      type,
      file,
    };
  },
});
