










































































































import ClientSelectInput from '@/components/inputs/ClientSelectInput.vue';
import QRCode from 'qrcode';
import mime from 'mime-types';
import { Client, Resource, ResourceTopic } from '@/models';
import { PropType, defineComponent, ref, watch } from '@/plugins/composition';
import { axios } from '@/plugins/axios';
import { routeNames } from '@/router/routeNames';
import { useRouter } from '@/router';

export default defineComponent({
  components: { ClientSelectInput },
  name: 'EditResourceForm',
  props: {
    resource: {
      type: Object as PropType<Resource>,
      required: true,
    },
    topic: {
      type: Object as PropType<ResourceTopic>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const editing = ref(false);
    const preview = ref(false);
    const client = ref<Client | null>(null);
    const qrcodeDialog = ref(false);

    const update = async () => {
      await props.resource.update();
      emit('input');
      editing.value = false;
    };

    const { router } = useRouter();

    const destroy = async () => {
      await props.resource.delete();
      emit('input');
    };

    const downloadQrCode = async () => {
      qrcodeDialog.value = false;
      qrcode();
    };

    const qrcode = async () => {
      try {
        const path = router.resolve({
          name: routeNames.patient.goToResource,
          query: { resource: props.resource.data.uuid, client: client.value?.uuid },
        });

        const url = window.location.protocol + '//' + window.location.host + path.href;

        try {
          navigator.clipboard.writeText(url);
        } catch (e) {
          console.log(url);
          // fail silently
        }

        const data = await QRCode.toDataURL(url, { color: { light: '#ffffff', dark: '#2abf64' }, width: 500 });

        const link = document.createElement('a');
        link.href = data;
        link.download = client.value?.name + ' - ' + props.resource.data.name;
        link.click();
        URL.revokeObjectURL(link.href);
      } catch (err) {
        console.error(err);
      }
    };

    watch(qrcodeDialog, async (qrcodeDialog: boolean) => {
      if (qrcodeDialog) {
        await props.topic.refresh();
      }
    });

    const download = async () => {
      const file = await axios.get(props.resource.directSlug() + 'download', { responseType: 'blob' });

      const blob = new Blob([file.data], { type: mime.lookup(props.resource.data.filePath as string) as string });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = props.resource.data.name;
      link.click();
      URL.revokeObjectURL(link.href);
    };

    return {
      editing,
      update,
      destroy,
      preview,
      qrcode,
      client,
      qrcodeDialog,
      downloadQrCode,
      download,
    };
  },
});
