







import { PropType, defineComponent } from '@/plugins/composition';
import { ResourceTopic } from '@/models';

export default defineComponent({
  name: 'EditTopicForm',
  props: {
    topic: {
      type: Object as PropType<ResourceTopic>,
      required: true,
    },
  },
  setup(props) {
    const save = async () => {
      await props.topic.update();
    };

    return {
      save,
    };
  },
});
