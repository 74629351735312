



































































import Component from 'vue-class-component';
import Vue from 'vue';
import { Client } from '@/models';
import { Prop, VModel, Watch } from 'vue-property-decorator';
import { debouncedSearch } from '@/utils/inputSearch';

@Component({
  name: 'ClientSelectInput',
})
export default class extends Vue {
  @VModel({ type: [Client, Object] }) client!: Client;
  @Prop({ type: String, default: 'Select a Client' })
  readonly label!: string;

  @Prop({ type: Boolean, default: false }) readonly allowAdd!: boolean;
  @Prop({ type: Boolean, default: false }) readonly allowEdit!: boolean;
  @Prop({ type: String, default: null }) readonly target!: string;

  items: Client[] = [];

  search = '';

  loading = false;
  editing: Client | null = null;

  searchClients = debouncedSearch(() => this.fetchClients());

  async setClient(client: string | Client): Promise<void> {
    if (!client) {
      this.$emit('input', null);
      return;
    }

    if (client instanceof Client) {
      this.$emit('input', client);
    }
  }

  async mounted(): Promise<void> {
    if (!(this.client instanceof Client) && this.client) {
      this.client = new Client(this.client);
    }
  }

  async fetchClients(): Promise<void> {
    this.loading = true;
    this.items = await Client.where('name', this.search).get();
    this.loading = false;
  }

  async edit(index: number, item: Client): Promise<void> {
    if (!this.editing) {
      this.editing = item;
    } else {
      this.editing = null;
      await item.update();
      this.searchClients();
    }
  }

  async create(name: string): Promise<void> {
    const response = await Client.create({ name });
    this.$emit('input', response);
    await this.fetchClients();
  }

  @Watch('search')
  async onSearchChanged(): Promise<void> {
    this.searchClients();
  }
}
